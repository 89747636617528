body {
    background-color: #fff !important;
}

.dropdown-toggle,
.dropdown-menu {
    width: 100%;
}

.nav-link {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem;
}

.dropdown-menu {
    min-width: 5rem !important;
}

.dropdown-item {
    padding: 0.25rem 1rem !important;
}

.btn-sm, .btn-group-sm > .btn {
    font-size: 0.75rem !important;
}

.pdf_button {
    width: 100px;
    margin: auto;
}

.nien_header {
    color:darkblue;
    font-weight: bold;
}

.nien_subheader {
    color:navy;
    font-weight: bold;
    font-size:large;
}

.nien_subheader_grey {
    color:grey;

}

.nien_estimate {
    font-weight: bold;
    font-size:x-large;
}

.nien_maptext {
    color:navy;
}

.nien_logout_menu {
    cursor: pointer;
}

.nien_billofmaterials_table {
 font-size: small;   
}

.align_right {
    text-align: right;
}

.fix_pdf_letter_spacing {
    letter-spacing: 1px;
}

.nowrap {
    white-space: nowrap;
}