.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin-left: 5px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  border-radius: 0.25rem !important;
  background-color: rgb(108, 117, 125);
  color: #fff;
  font-size: 0.75em;
  display: inline-block;
  padding: 0px 0px 2px 5px;
  margin: 0px 2px;
  border-radius: 2px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #00aae4;
  font-size: 17px;
  text-align: center;
}

.ReactTags__remove:hover {
  color: red;
}
